import { http } from '../services/http'
import Cookie from 'js-cookie'
import Strings from './../common/strings'

export default{
    login: (cpf_cnpj, senha, imobiliariaId) => {
        return http.post('/Usuario/autenticar?CpfCnpj=' + cpf_cnpj + '&Senha=' + senha + '&ImobiliariaId=' + imobiliariaId)
    },
    precadastro: (payload) => {
        return http.post('/Usuario/pre/cadastro', JSON.stringify(payload))
    },
    confirmarcadastro: (payload) => {
        return http.post('/Usuario/confirma/cadastro', JSON.stringify(payload))
    },
    alterarSenha: (payload) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Usuario/alterar/senha', JSON.stringify(payload), option)
    },
    recuperarSenha: (cpf_cnpj, imobiliariaId) => {
        return http.post('/Usuario/recuperar/senha?CpfCnpj=' + cpf_cnpj + '&ImobiliariaId=' + imobiliariaId)
    },
    dadospessoa: (imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/cliente/dados/' + imobiliariaId, null, option)
    },
    dashboard: () => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/dashboard', null, option)
    },
    empreendimentos: (imobiliariaId) => {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/empreendimentos/obter/' + imobiliariaId, null, option)
    },
    unidades: (payload, imobiliariaId) => {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/unidades/empreendimentos/obter/' + imobiliariaId, JSON.stringify(payload), option)
    },
    boletosreimpressao: (payload) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/boletos/reimpressao/obter', payload, option)
    },
    boletosVencidos: (imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Portal/cliente/boletos/vencidos/obter/' + imobiliariaId, null, option)
    },
    gerarPdfBoleto: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Portal/cliente/boletos/gerarpdf/' + imobiliariaId, JSON.stringify(payload), option)
    },
    resumovenda: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/venda/resumo/' + imobiliariaId, JSON.stringify(payload), option)
    },
    extratoResumido: (imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/venda/extrato/resumido/' + imobiliariaId, null, option)
    },
    gerarPDFDemonstrativoPgto: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/cliente/gerar/pdf/extratopgto/' + imobiliariaId, JSON.stringify(payload), option)
    },
    parcelasDetalhado: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/venda/parcelas/detalhes/' + imobiliariaId, JSON.stringify(payload), option)
    },
    gerarPDFIR: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/cliente/gerar/pdfir/' + imobiliariaId, JSON.stringify(payload), option)
    },
    reportarBug: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/report/bug/' + imobiliariaId, JSON.stringify(payload), option)
    },
    obra: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.post('/Portal/obra/consultar/' + imobiliariaId, JSON.stringify(payload), option)
    },
    identificarCadastro: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Usuario/identificar/cadastro/' + imobiliariaId + '?CpfCnpj=' + payload.cpf_cnpj + '&empreendimento=' + payload.empreendimento, null, option)
    },
    identificarCadastroAlternativo: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Usuario/identificar/cadastro/dadospessoais/' + imobiliariaId + '?nomeCompleto=' + payload.nomeCompleto + '&cpfCnpj=' + payload.cpf_cnpj + '&dataNascimento=' + payload.dataNascimento, null, option)
    },
    alterarEmailPessoa: (payload, imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Usuario/alterar/email/pessoa/' + imobiliariaId + '?CpfCnpj=' + payload.cpf_cnpj + '&email=' + payload.email, null, option)
    },
    obterContatoObra: (imobiliariaId) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }
        return http.post('/Portal/imobiliaria/obter/contatos/' + imobiliariaId, null, option)
    },
    obterContatosPorAppI: (imobiliariaId, appId) => {
        return http.post(`/Portal/imobiliaria/obter/contatos/${imobiliariaId}/${appId}`, null)
    },
    obterAcompanhamento: (imobiliariaId) => {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.get('/Acompanhamento/obter/' + imobiliariaId, option)
    },
    listarTodosAnuncios: (imobiliariaId) => {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.get('/Anuncio/listar/imobiliaria/' + imobiliariaId, option)
    },
    listarPorCodPessoa: (imobiliariaId) => {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.get('/Anuncio/listar/usuario/' + imobiliariaId, option)
    },
    anunciarUnidade: (payload) => {
        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
              'Content-Type': 'multipart/form-data'
            }
        }
        return http.post('/Anuncio/anunciar', payload, option)
    },
    excluirAnuncio: (id) => {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.delete('/Anuncio/excluir/' + id, option)
    },
    obterPastasUsuario: () => {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.get('/Usuario/pastas', option)
    },
    obterConteudoPasta: (pastaId) => {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.get(`/Usuario/pasta/${pastaId}`, option)
    },
    obterPastasObra: (obraId)=> {

        let option = {
            headers: {
              'Authorization': `${Cookie.get(Strings.cookie_token)}`,
            }
        }

        return http.get(`/Usuario/obra/pastas/${obraId}`, option)
    },
}
